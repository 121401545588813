import { Phone } from "react-bootstrap-icons";
import {
  Home,
  Folder,
  Database,
  Activity,
  User,
  DollarSign,
  Users,
  FileText,
} from "react-feather";

export const MENUITEMS = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: false,

        children: [
          {
            path: "/admin/dashboard",
            type: "link",
            title: "Dashboard",
            active: false,
          },
          {
            path: "/admin/studentperformance",
            title: "Student Performance",
            type: "link",
            active: false,
          },
          // {
          //   path: "/admin/advisorperformance",
          //   title: "Facilitator Performance",
          //   type: "link",
          //   active: false,
          // }, 
          // {
          //   path: "/admin/instructorperformance",
          //   title: "Instructor Performance",
          //   active: false,
          //   type: "link",
          // }, 
          {
            path: "/admin/proforma",
            title: "Proforma",
            active: false,
            type: "link",
          },
        ],
      },

      {
        title: "Student Analysis",
        icon: Database,
        type: "sub",
        active: false,

        children: [
          {
            path: "/admin/studentdata",
            type: "link",
            title: "Students Data",
            active: false,
          },
          {
            path: "/admin/attendanceAnalysis",
            title: "Attendance Analysis",
            type: "link",
            active: false,
          },
          {
            path: "/admin/globalnalysis",
            title: "Global Data Analysis",
            type: "link",
            active: false,
          },
        ],
      },
      {
        title: "In Depth",
        icon: Activity,
        type: "sub",
        active: false,

        children: [
          {
            title: "1-1 Sessions",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/oneononesession",
                title: "1-1 Sessions",
                type: "link",
                active: false,
              },
              {
                path: "/admin/onesessionexcel",
                title: "Session Upload Excel",
                type: "link",
                active: false,
              },
            ],
          },
          {
            title: "Portfolio Projects",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/portfolioproject",
                title: "Portfolio Projects",
                type: "link",
                active: false,
              },
              {
                path: "/admin/portfolioprojectexcel",
                title: "Portfolio Upload Excel",
                type: "link",
                active: false,
              },
            ],
          },
          {
            path: "/admin/dropout",
            type: "link",
            active: false,
            title: "Dropouts",
          },
          {
            path: "/admin/feedbackStudents",
            type: "link",
            active: false,
            title: "Feedback on Students",
          },
          {
            path: "/admin/placementInterviews",
            type: "link",
            active: false,
            title: "Placement Interviews",
          },
          // PD Analysis
          {
            title: "PD Analysis",
            type: "sub",
            active: false,
            children: [
              {
                title: "PD Check-in",
                type: "sub",
                active: false,
                children: [
                  // {
                  //   path: "/admin/checkInTA",
                  //   title: "Check-in (TA)",
                  //   type: "link",
                  //   active: false,
                  // },
                  {
                    path: "/admin/checkInFacilitator",
                    title: "Check-in (Facilitator)",
                    type: "link",
                    active: false,
                  },
                  {
                    path: "/admin/checkInPlacement",
                    title: "Check-in (Placements)",
                    type: "link",
                    active: false,
                  },
                ],
              },
              {
                path: "/admin/pdAssignments",
                type: "link",
                active: false,
                title: "PD Assignments",
              },
              {
                path: "/admin/pdDashboard",
                type: "link",
                active: false,
                title: "PD Dashboard",
              },
            ],
          },
          // Pledges
          {
            title: "Pledges",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/student_data",
                title: "Students Data",
                type: "link",
                active: false,
              },
              {
                path: "/admin/individual_student_data",
                title: "Individual Student Data",
                type: "link",
                active: false,
              },
              {
                path: "/admin/sessions_score",
                title: "Attendance",
                type: "link",
                active: false,
              },
              {
                path: "/admin/assignments_score",
                title: "Assignment",
                type: "link",
                active: false,
              },
              {
                path: "/admin/loan_repayment_excel",
                title: "Loan Repayment Excel",
                type: "link",
                active: false,
              },
              {
                path: "/admin/view_loan_repayment",
                title: "View Loan Repayment",
                type: "link",
                active: false,
              },
            ],
          },
        ],
      },
      {
        title: "Others",
        icon: Folder,
        type: "sub",
        active: false,
        children: [
          {
            menutitle: "Student",
            menucontent: "",
            icon: <Users size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            active: false,
            children: [
              {
                path: "/admin/certificate",
                type: "link",
                active: false,
                title: "Certification",
              },

              {
                path: `/admin/studentgrading`,
                type: "link",
                active: false,
                title: "Student Individual Grading",
              },

              {
                path: "/admin/addstudents",
                type: "link",
                active: false,
                title: "Add New Students",
              },
              {
                path: "/admin/narrativeupdate",
                type: "link",
                active: false,
                title: "Narrative Update",
              },
              {
                path: "/admin/financialaid",
                type: "link",
                active: false,
                title: "Financial Aid",
              },
              {
                path: "/admin/graduationsheet",
                type: "link",
                active: false,
                title: "Graduation Sheet",
              },
              {
                path: "/admin/mentorship_sessions",
                type: "link",
                active: false,
                title: "Mentorship Session",
              },
              {
                path: "/admin/mentorship_program",
                type: "link",
                active: false,
                title: "Mentorship Program",
              },
            ],
          },
          {
            menutitle: "Attendance",
            icon: <FileText size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/instructor_upload_Assignment",
                type: "link",
                active: false,
                title: "Instructor Assignment & Bootcamp",
              },
              {
                path: "/admin/facilitator_upload_Assignment",
                type: "link",
                active: false,
                title: "Facilitator Assignment & Bootcamp",
              },
              {
                path: "/admin/monthlySummary",
                type: "link",
                active: false,
                title: "Monthly Summary",
              },
              {
                path: "/admin/pdSession",
                type: "link",
                active: false,
                title: "PD Session",
              },
            ],
          },
          {
            menutitle: "Team Evaluations",
            icon: <Folder size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/facilitator_performance",
                title: "Facilitator Performance",
                active: false,
                type: "link",
              },
              {
                path: "/admin/instructor_performance",
                title: "Instructor Performance",
                type: "link",
                active: false,
              },
              {
                path: "/admin/pd_performance",
                title: "PD Performance",
                type: "link",
                active: false,
              },
            ],
          },
          {
            menutitle: "Payments",
            icon: <DollarSign size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/lnd_payment",
                type: "link",
                active: false,
                title: "LND Payment",
              },
              {
                path: "/admin/placement_payment",
                type: "link",
                active: false,
                title: "Placement Payment",
              },
              {
                path: "/admin/lec_payment",
                type: "link",
                active: false,
                title: "LEC Payment",
              },
            ],
          },
          
          {
            menutitle: "Admin",
            icon: <User size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                title: "Users",
                type: "sub",
                active: false,
                children: [
                  {
                    path: "/admin/students",
                    title: "Students",
                    type: "link",
                    active: false,
                  },
                  {
                    path: "/admin/users",
                    title: "Others",
                    type: "link",
                    active: false,
                  },
                  {
                    path: "/admin/manage_mentors",
                    type: "link",
                    active: false,
                    title: "Manage Mentors",
                  },
                ],
              },
              {
                title: "Cohort & Bootcamps",
                type: "sub",
                active: false,

                children: [
                  {
                    path: "/admin/addcohort",
                    title: "Create Cohort",
                    type: "link",
                    active: false,
                  },
                  {
                    path: "/admin/viewcohorts",
                    title: "View Cohorts",
                    type: "link",
                    active: false,
                  },
                ],
              },
              {
                path: "/admin/placement",
                type: "link",
                active: false,
                title: "Placement Data",
              },
            ],
          },
          {
            menutitle: "Mobile App",
            icon: <Phone size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/sendnotifications",
                title: "Send Notifications",
                type: "link",
                active: false,
              },
              {
                path: "/admin/notificationhistory",
                title: "History",
                type: "link",
                active: false,
              },
              {
                path: `/admin/studenttasks`,
                type: "link",
                active: false,
                title: "Student Tasks",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const ADVISOR = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/advisor/dashboard",
            type: "link",
            title: "Dashboard",
          },
          {
            path: "/advisor/students_data",
            type: "link",
            title: "Students Data",
          },
          {
            path: "/advisor/student_performance",
            title: "Student Performance",
            type: "link",
          },
          // {
          //   path: "/advisor/ta_performance",
          //   type: "link",
          //   active: false,
          //   title: "TA Performance",
          // },
        ],
      },

      {
        title: "In Depth",
        icon: Activity,
        type: "sub",
        active: false,

        children: [
          {
            title: "1-1 Sessions",
            type: "sub",
            active: false,
            children: [
              {
                path: "/advisor/1On1Session",
                title: "1-1 Sessions",
                type: "link",
              },
              {
                path: "/advisor/1SessionExcel",
                title: "Session Upload Excel",
                type: "link",
              },
            ],
          },
          {
            title: "Portfolio Projects",
            type: "sub",
            active: false,
            children: [
              {
                path: "/advisor/Portfolio_Project",
                title: "Portfolio Projects",
                type: "link",
              },
              {
                path: "/advisor/Portfolio_Project_Excel",
                title: "Portfolio Upload Excel",
                type: "link",
              },
            ],
          },
          {
            path: "/advisor/Dropouts",
            type: "link",
            active: false,
            title: "Dropouts",
          },
          // {
          //   path: "/meet_excel",
          //   type: "link",
          //   active: false,
          //   title: "Google Meet Excel",
          // },
          {
            path: "/advisor/feedback_students",
            type: "link",
            active: false,
            title: "Feedback on Students",
          },
          // {
          //   path: "/placement_Interviews",
          //   type: "link",
          //   active: false,
          //   title: "Placement Interviews",
          // },
          // {
          //   title: "PD Interviews",
          //   type: "sub",
          //   active: false,
          //   children: [
          //     {
          //       path: "/pd_Interview",
          //       title: "PD Interviews",
          //       type: "link",
          //     },
          //     {
          //       path: "/pdInterview_Excel",
          //       title: "PD Upload Excel",
          //       type: "link",
          //     },
          //   ],
          // },

          // PD Analysis

          {
            title: "PD Analysis",
            type: "sub",
            active: false,
            children: [
              {
                title: "PD Check-in",
                type: "sub",
                active: false,
                children: [
                  // {
                  //   path: "/advisor/checkIn_TA",
                  //   title: "Check-in (TA)",
                  //   type: "link",
                  // },
                  {
                    path: "/advisor/checkInFacilitator",
                    title: "Check-in (Facilitator)",
                    type: "link",
                  },
                  {
                    path: "/advisor/checkIn_Placement",
                    title: "Check-in (Placements)",
                    type: "link",
                  },
                ],
              },
              {
                path: "/advisor/pd_Assignments",
                type: "link",
                active: false,
                title: "PD Assignments",
              },
              {
                path: "/advisor/pd_Dashboard",
                type: "link",
                active: false,
                title: "PD Dashboard",
              },
            ],
          },
        ],
      },
      {
        title: "Others",
        icon: Folder,
        type: "sub",
        active: false,
        children: [
          {
            menutitle: "Student",
            menucontent: "",
            icon: <Users size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/student_certificate",
                type: "link",
                active: false,
                title: "Certification",
              },

              {
                path: `/advisor/student_grading`,
                type: "link",
                active: false,
                title: "Student Individual Grading",
              },

              {
                path: "/advisor/narrative_update",
                type: "link",
                active: false,
                title: "Narrative Update",
              },

              {
                path: "/advisor/graduation_sheet",
                type: "link",
                active: false,
                title: "Graduation Sheet",
              },
              // {
              //   path: "/advisor/mentorship_program",
              //   type: "link",
              //   active: false,
              //   title: "Mentorship Program",
              // },
            ],
          },
          {
            menutitle: "Attendance",
            icon: <FileText size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/instructor_upload_Assignment",
                type: "link",
                active: false,
                title: "Instructor Assignment & Bootcamp",
              },
              {
                path: "/advisor/facilitator_upload_Assignment",
                type: "link",
                active: false,
                title: "Facilitator Assignment & Bootcamp",
              },
              {
                path: "/advisor/monthly_Summary",
                type: "link",
                active: false,
                title: "Monthly Summary",
              },
              {
                path: "/advisor/pd_Session",
                type: "link",
                active: false,
                title: "PD Session",
              },
            ],
          },
          {
            menutitle: "Payments",
            icon: <DollarSign size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/lnd_payment",
                type: "link",
                active: false,
                title: "LND Payment",
              },
              {
                path: "/advisor/placement_payment",
                type: "link",
                active: false,
                title: "Placement Payment",
              },
              {
                path: "/advisor/lec_payment",
                type: "link",
                active: false,
                title: "LEC Payment",
              },
            ],
          },
          {
            menutitle: "Users",
            icon: <User size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              // {
              //   path: "/advisor/manage_tA",
              //   title: "Manage TA",
              //   type: "link",
              // },
              {
                path: "/advisor/students",
                title: "Students Login",
                type: "link",
              },
              // {
              //   path: "/advisor/manage_mentors",
              //   type: "link",
              //   active: false,
              //   title: "Manage Mentors",
              // },
              {
                path: "/advisor/mentorship_sessions",
                type: "link",
                active: false,
                title: "Mentorship Session",
              },
            ],
          },
          // {
          //   menutitle: "Team Evaluations",
          //   icon: <Folder size={16} style={{ marginRight: 5 }} />,
          //   type: "heading",
          //   children: [ 
          //     {
          //       path: "/advisor/instructorperformance",
          //       title: "Instructor Performance",
          //       type: "link",
          //     },
          //   ],
          // }, 

          {
            menutitle: "Mobile App",
            icon: <Phone size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/send_notification",
                title: "Send Notifications",
                type: "link",
                active: false,
              },
              {
                path: "/advisor/notifications_history",
                title: "History",
                type: "link",
                active: false,
              },
              {
                path: `/advisor/student_tasks`,
                type: "link",
                active: false,
                title: "Student Tasks",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const ORGANISER = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/organiser/financial_aid",
            type: "link",
            title: "Financial Aid",
          },
          {
            title: "Pledges",
            type: "sub",
            active: false,
            children: [
              {
                path: "/organiser/pledges/studentdata",
                title: "Students Data",
                type: "link",
              },
              {
                path: "/organiser/pledges/individualStudentData",
                title: "Individual Student Data",
                type: "link",
              },
              {
                path: "/organiser/sessionsScore",
                title: "Attendance & Assignment",
                type: "link",
              },
              {
                path: "/organiser/loanRepaymentExcel",
                title: "Loan Repayment Excel",
                type: "link",
              },
              {
                path: "/organiser/viewLoanRepayment",
                title: "View Loan Repayment",
                type: "link",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const DIRECTOR = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/director/dashboard",
            type: "link",
            title: "Dashboard",
          },
        ],
      },
    ],
  },
];

export const PLACEMENT = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/placement/dashboard",
            type: "link",
            title: "Dashboard",
          },
          {
            path: "/placement/placement_data",
            type: "link",
            active: false,
            title: "Placement Data",
          },
          {
            title: "Payments",
            type: "sub",
            active: false,

            children: [
              {
                path: "/placement/lnd_payment",
                type: "link",
                active: false,
                title: "LND Payment",
              },
              {
                path: "/placement/placement_payment",
                type: "link",
                active: false,
                title: "Placement Payment",
              },
              {
                path: "/placement/lec_payment",
                type: "link",
                active: false,
                title: "LEC Payment",
              },
            ],
          },
          {
            title: "Mobile App",
            type: "sub",
            active: false,

            children: [
              {
                path: "/placement/send_notifications",
                title: "Send Notifications",
                type: "link",
              },
              {
                path: "/placement/notification_history",
                title: "History",
                type: "link",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const WITHPDINTERVIEW = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/admin/dashboard",
            type: "link",
            title: "Dashboard",
          },
          {
            path: "/admin/studentperformance",
            title: "Student Performance",
            type: "link",
          },
          {
            path: "/admin/proforma",
            title: "Proforma",
            active: false,
            type: "link",
          },
          // {
          //   path: "/admin/advisorperformance",
          //   title: "Advisor Performance",
          //   type: "link",
          // },
          // {
          //   path: "/admin/instructorperformance",
          //   title: "Instructor Performance",
          //   active: false,
          //   type: "link",
          // },

          // {
          //   path: "/admin/taPerformance",
          //   type: "link",
          //   active: false,
          //   title: "TA Performance",
          // },
        ],
      },

      {
        title: "Student Analysis",
        icon: Database,
        type: "sub",
        active: false,

        children: [
          {
            path: "/admin/studentdata",
            type: "link",
            title: "Students Data",
          },
          {
            path: "/admin/attendanceAnalysis",
            title: "Attendance Analysis",
            type: "link",
          },
          {
            path: "/admin/globalnalysis",
            title: "Global Data Analysis",
            type: "link",
          },
        ],
      },
      {
        title: "In Depth",
        icon: Activity,
        type: "sub",
        active: false,

        children: [
          {
            title: "1-1 Sessions",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/oneononesession",
                title: "1-1 Sessions",
                type: "link",
              },
              {
                path: "/admin/onesessionexcel",
                title: "Session Upload Excel",
                type: "link",
              },
            ],
          },
          {
            title: "Portfolio Projects",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/portfolioproject",
                title: "Portfolio Projects",
                type: "link",
              },
              {
                path: "/admin/portfolioprojectexcel",
                title: "Portfolio Upload Excel",
                type: "link",
              },
            ],
          },
          {
            path: "/admin/dropout",
            type: "link",
            active: false,
            title: "Dropouts",
          },
          {
            path: "/admin/feedbackStudents",
            type: "link",
            active: false,
            title: "Feedback on Students",
          },
          {
            path: "/admin/placementInterviews",
            type: "link",
            active: false,
            title: "Placement Interviews",
          },
          {
            title: "PD Interviews",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/pdInterview",
                title: "PD Interviews",
                type: "link",
              },
              {
                path: "/admin/pdInterviewexcel",
                title: "PD Upload Excel",
                type: "link",
              },
            ],
          },
          {
            title: "PD Analysis",
            type: "sub",
            active: false,
            children: [
              {
                title: "PD Check-in",
                type: "sub",
                active: false,
                children: [
                  {
                    path: "/admin/checkInTA",
                    title: "Check-in (TA)",
                    type: "link",
                  },
                  {
                    path: "/admin/checkInAdvisors",
                    title: "Check-in (Advisors)",
                    type: "link",
                  },
                  {
                    path: "/admin/checkInPlacement",
                    title: "Check-in (Placements)",
                    type: "link",
                  },
                ],
              },
              {
                path: "/admin/pdAssignments",
                type: "link",
                active: false,
                title: "PD Assignments",
              },
              {
                path: "/admin/pdDashboard",
                type: "link",
                active: false,
                title: "PD Dashboard",
              },
            ],
          },
          {
            title: "Pledges",
            type: "sub",
            active: false,
            children: [
              {
                path: "/admin/student_data",
                title: "Students Data",
                type: "link",
              },
              {
                path: "/admin/individual_student_data",
                title: "Individual Student Data",
                type: "link",
              },
              {
                path: "/admin/sessions_score",
                title: "Attendance",
                type: "link",
              },
              {
                path: "/admin/assignments_score",
                title: "Assignment",
                type: "link",
              },
              {
                path: "/admin/loan_repayment_excel",
                title: "Loan Repayment Excel",
                type: "link",
              },
              {
                path: "/admin/view_loan_repayment",
                title: "View Loan Repayment",
                type: "link",
              },
            ],
          },
        ],
      },
      {
        title: "Others",
        icon: Folder,
        type: "sub",
        active: false,
        children: [
          {
            menutitle: "Student",
            menucontent: "",
            icon: <Users size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/certificate",
                type: "link",
                active: false,
                title: "Certification",
              },

              {
                path: `/admin/studentgrading`,
                type: "link",
                active: false,
                title: "Student Individual Grading",
              },

              {
                path: "/admin/addstudents",
                type: "link",
                active: false,
                title: "Add New Students",
              },
              {
                path: "/admin/narrativeupdate",
                type: "link",
                active: false,
                title: "Narrative Update",
              },
              {
                path: "/admin/financialaid",
                type: "link",
                active: false,
                title: "Financial Aid",
              },
              {
                path: "/admin/graduationsheet",
                type: "link",
                active: false,
                title: "Graduation Sheet",
              },
            ],
          },
          {
            menutitle: "Attendance",
            icon: <FileText size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              // {
              //   path: "/admin/uploadAssignment",
              //   type: "link",
              //   active: false,
              //   title: "Upload Assignment and Bootcamp",
              // },
              {
                path: "/admin/monthlySummary",
                type: "link",
                active: false,
                title: "Monthly Summary",
              },
              {
                path: "/admin/pdSession",
                type: "link",
                active: false,
                title: "PD Session",
              },
            ],
          },
          {
            menutitle: "Team Evaluations",
            icon: <Folder size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/facilitator_performance",
                title: "Facilitator Performance", 
                active: false,
                type: "link",
              },
              {
                path: "/admin/instructorperformance",
                title: "Instructor Performance",
                type: "link",
              },
              {
                path: "/admin/meeting",
                type: "link",
                active: false,
                title: "TA Meeting",
              },
            ],
          },
          {
            menutitle: "Admin",
            icon: <User size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                title: "Users",
                type: "sub",
                active: false,
                children: [
                  {
                    path: "/admin/students",
                    title: "Students",
                    type: "link",
                  },
                  {
                    path: "/admin/users",
                    title: "Others",
                    type: "link",
                  },
                ],
              },
              {
                title: "Cohort and Bootcamps",
                type: "sub",
                active: false,

                children: [
                  {
                    path: "/admin/addcohort",
                    title: "Create Cohort",
                    type: "link",
                  },
                  {
                    path: "/admin/viewcohorts",
                    title: "View Cohorts",
                    type: "link",
                  },
                ],
              },
              {
                path: "/admin/placement",
                type: "link",
                active: false,
                title: "Placement Data",
              },
            ],
          },
          {
            menutitle: "Mobile App",
            icon: <Phone size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/admin/sendnotifications",
                title: "Send Notifications",
                type: "link",
                active: false,
              },
              {
                path: "/admin/notificationhistory",
                title: "History",
                type: "link",
                active: false,
              },
              {
                path: `/admin/studenttasks`,
                type: "link",
                active: false,
                title: "Student Tasks",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const TA = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/ta/dashboard",
            type: "link",
            title: "Dashboard",
          },
          {
            path: "/ta/students_data",
            type: "link",
            title: "Students Data",
          },
        ],
      },

      {
        title: "In Depth",
        icon: Activity,
        type: "sub",
        active: false,

        children: [
          {
            title: "1-1 Sessions",
            type: "sub",
            active: false,
            children: [
              {
                path: "/ta/1_on_1_session",
                title: "1-1 Sessions",
                type: "link",
              },
              {
                path: "/ta/1_session_excel",
                title: "Session Upload Excel",
                type: "link",
              },
            ],
          },
          {
            title: "Portfolio Projects",
            type: "sub",
            active: false,
            children: [
              {
                path: "/ta/portfolio_project",
                title: "Portfolio Projects",
                type: "link",
              },
              {
                path: "/ta/portfolio_project_excel",
                title: "Portfolio Upload Excel",
                type: "link",
              },
            ],
          },

          {
            path: "/ta/feedback_students",
            type: "link",
            active: false,
            title: "Feedback on Students",
          },

          {
            title: "PD Analysis",
            type: "sub",
            active: false,
            children: [
              {
                title: "PD Check-in",
                type: "sub",
                active: false,
                children: [
                  {
                    path: "/ta/checkIn_TA",
                    title: "Check-in (TA)",
                    type: "link",
                  },
                ],
              },
              {
                path: "/ta/pd_assignments",
                type: "link",
                active: false,
                title: "PD Assignments",
              },
              {
                path: "/ta/pdDashboard",
                type: "link",
                active: false,
                title: "PD Dashboard",
              },
            ],
          },
        ],
      },
      {
        title: "Others",
        icon: Folder,
        type: "sub",
        active: false,
        children: [
          {
            menutitle: "Attendance",
            icon: <FileText size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/ta/upload_assignment",
                type: "link",
                active: false,
                title: "Upload Assignment and Bootcamp",
              },
              {
                path: "/ta/ta_session",
                type: "link",
                active: false,
                title: "TA Session",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const CAREER = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/career/dashboard",
            type: "link",
            title: "Dashboard",
          },
          {
            path: "/career/students_data",
            type: "link",
            title: "Students Data",
          },
          {
            path: "/career/student_performance",
            title: "Student Performance",
            type: "link",
          },
        ],
      },

      {
        title: "In Depth",
        icon: Activity,
        type: "sub",
        active: false,

        children: [
          {
            title: "PD Analysis",
            type: "sub",
            active: false,
            children: [
              {
                title: "PD Check-in",
                type: "sub",
                active: false,
                children: [
                  {
                    path: "/career/checkIn_Placement",
                    title: "Check-in (Placements)",
                    type: "link",
                  },
                ],
              },
              {
                path: "/career/pdDashboard",
                type: "link",
                active: false,
                title: "PD Dashboard",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const MENTOR = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/mentor/mentorship_sessions",
            type: "link",
            title: "Mentorship Session",
          },
        ],
      },
    ],
  },
];


export const PD = [
  {
    Items: [
      {
        title: "General",
        icon: Home,
        type: "sub",
        active: true,

        children: [
          {
            path: "/advisor/dashboard",
            type: "link",
            title: "Dashboard",
          },
          {
            path: "/advisor/students_data",
            type: "link",
            title: "Students Data",
          },
          {
            path: "/advisor/student_performance",
            title: "Student Performance",
            type: "link",
          },
          // {
          //   path: "/advisor/ta_performance",
          //   type: "link",
          //   active: false,
          //   title: "TA Performance",
          // },
        ],
      },

      {
        title: "In Depth",
        icon: Activity,
        type: "sub",
        active: false,

        children: [
          {
            title: "1-1 Sessions",
            type: "sub",
            active: false,
            children: [
              {
                path: "/advisor/1On1Session",
                title: "1-1 Sessions",
                type: "link",
              },
              {
                path: "/advisor/1SessionExcel",
                title: "Session Upload Excel",
                type: "link",
              },
            ],
          },
          {
            title: "Portfolio Projects",
            type: "sub",
            active: false,
            children: [
              {
                path: "/advisor/Portfolio_Project",
                title: "Portfolio Projects",
                type: "link",
              },
              {
                path: "/advisor/Portfolio_Project_Excel",
                title: "Portfolio Upload Excel",
                type: "link",
              },
            ],
          },
          {
            path: "/advisor/Dropouts",
            type: "link",
            active: false,
            title: "Dropouts",
          },
          // {
          //   path: "/meet_excel",
          //   type: "link",
          //   active: false,
          //   title: "Google Meet Excel",
          // },
          {
            path: "/advisor/feedback_students",
            type: "link",
            active: false,
            title: "Feedback on Students",
          },
          // {
          //   path: "/placement_Interviews",
          //   type: "link",
          //   active: false,
          //   title: "Placement Interviews",
          // },
          // {
          //   title: "PD Interviews",
          //   type: "sub",
          //   active: false,
          //   children: [
          //     {
          //       path: "/pd_Interview",
          //       title: "PD Interviews",
          //       type: "link",
          //     },
          //     {
          //       path: "/pdInterview_Excel",
          //       title: "PD Upload Excel",
          //       type: "link",
          //     },
          //   ],
          // },

          // PD Analysis

          {
            title: "PD Analysis",
            type: "sub",
            active: false,
            children: [
              {
                title: "PD Check-in",
                type: "sub",
                active: false,
                children: [
                  // {
                  //   path: "/advisor/checkIn_TA",
                  //   title: "Check-in (TA)",
                  //   type: "link",
                  // },
                  {
                    path: "/advisor/checkInFacilitator",
                    title: "Check-in (Facilitator)",
                    type: "link",
                  },
                  {
                    path: "/advisor/checkIn_Placement",
                    title: "Check-in (Placements)",
                    type: "link",
                  },
                ],
              },
              {
                path: "/advisor/pd_Assignments",
                type: "link",
                active: false,
                title: "PD Assignments",
              },
              {
                path: "/advisor/pd_Dashboard",
                type: "link",
                active: false,
                title: "PD Dashboard",
              },
            ],
          },
        ],
      },
      {
        title: "Others",
        icon: Folder,
        type: "sub",
        active: false,
        children: [
          {
            menutitle: "Student",
            menucontent: "",
            icon: <Users size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/student_certificate",
                type: "link",
                active: false,
                title: "Certification",
              },

              {
                path: `/advisor/student_grading`,
                type: "link",
                active: false,
                title: "Student Individual Grading",
              },

              {
                path: "/advisor/narrative_update",
                type: "link",
                active: false,
                title: "Narrative Update",
              },

              {
                path: "/advisor/graduation_sheet",
                type: "link",
                active: false,
                title: "Graduation Sheet",
              },
              // {
              //   path: "/advisor/mentorship_program",
              //   type: "link",
              //   active: false,
              //   title: "Mentorship Program",
              // },
            ],
          },
          {
            menutitle: "Attendance",
            icon: <FileText size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/instructor_upload_Assignment",
                type: "link",
                active: false,
                title: "Instructor Assignment & Bootcamp",
              },
              {
                path: "/advisor/facilitator_upload_Assignment",
                type: "link",
                active: false,
                title: "Facilitator Assignment & Bootcamp",
              },
              {
                path: "/advisor/monthly_Summary",
                type: "link",
                active: false,
                title: "Monthly Summary",
              },
              {
                path: "/advisor/pd_Session",
                type: "link",
                active: false,
                title: "PD Session",
              },
            ],
          },
          {
            menutitle: "Payments",
            icon: <DollarSign size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/lnd_payment",
                type: "link",
                active: false,
                title: "LND Payment",
              },
              {
                path: "/advisor/placement_payment",
                type: "link",
                active: false,
                title: "Placement Payment",
              },
              {
                path: "/advisor/lec_payment",
                type: "link",
                active: false,
                title: "LEC Payment",
              },
            ],
          },
          {
            menutitle: "Users",
            icon: <User size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              // {
              //   path: "/advisor/manage_tA",
              //   title: "Manage TA",
              //   type: "link",
              // },
              {
                path: "/advisor/students",
                title: "Students Login",
                type: "link",
              },
              
              {
                path: "/advisor/manage_mentors",
                type: "link",
                active: false,
                title: "Manage Mentors",
              },
              {
                path: "/advisor/mentorship_sessions",
                type: "link",
                active: false,
                title: "Mentorship Session",
              },
            ],
          },
          // {
          //   menutitle: "Team Evaluations",
          //   icon: <Folder size={16} style={{ marginRight: 5 }} />,
          //   type: "heading",
          //   children: [ 
          //     {
          //       path: "/advisor/instructorperformance",
          //       title: "Instructor Performance",
          //       type: "link",
          //     },
          //   ],
          // }, 

          {
            menutitle: "Mobile App",
            icon: <Phone size={16} style={{ marginRight: 5 }} />,
            type: "heading",
            children: [
              {
                path: "/advisor/send_notification",
                title: "Send Notifications",
                type: "link",
                active: false,
              },
              {
                path: "/advisor/notifications_history",
                title: "History",
                type: "link",
                active: false,
              },
              {
                path: `/advisor/student_tasks`,
                type: "link",
                active: false,
                title: "Student Tasks",
              },
            ],
          },
        ],
      },
    ],
  },
];