import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  MENUITEMS as menus,
  ADVISOR,
  ORGANISER,
  DIRECTOR,
  PLACEMENT,
  TA,
  CAREER,
  MENTOR,
  PD,
  // PD
} from "./menu";
import {
  ArrowRight,
  ArrowLeft,
  Grid,
  User,
  Bell,
  FileText,
  Folder,
  Users,
  DollarSign,
} from "react-feather";
import { Link } from "react-router-dom";
import configDB from "../../data/customizer/config";
import { DefaultLayout } from "../theme-customizer";
import { App } from "react-bootstrap-icons";

const Sidebar = (props) => {
  const [user, setUser] = useState({});

  //  get logged in user's data
  useEffect(() => {
    const getCookie = (name) => {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    };

    const cookieData = getCookie("userDetails");
    const data_list =
      Object.keys(cookieData).length > 0
        ? JSON.parse(decodeURIComponent(cookieData))
        : {};
    setUser(data_list);
  }, []);

  const id = window.location.pathname;
  const layout = id ? id : "Dubai";
  const role = user?.role ? user?.role?.toString() : "";
  const MENUITEMS =
    role === "admin"
      ? menus
      : role === "data organiser"
      ? ORGANISER
      : role === "director"
      ? DIRECTOR
      : role === "placement"
      ? PLACEMENT
      : role === "ta"
      ? TA
      : role === "career"
      ? CAREER
      : role === "mentor"
      ? MENTOR
      : role ==="pd" ? PD: ADVISOR;
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const wrapper =
    useSelector((content) => content.Customizer.sidebar_types.type) ||
    configDB.data.settings.sidebar.type;

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "material-type" ||
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "advance-layout"
      )
        document.querySelector(".sidebar-main").className =
          "sidebar-main hovered";
    } else {
      document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);
    handleResize();

    const get_user = layout?.split("/");
    const user_role = get_user[1];
    const user_menu =
      user_role === "admin"
        ? menus
        : user_role === "data organiser"
        ? ORGANISER
        : user_role === "director"
        ? DIRECTOR
        : user_role === "placement"
        ? PLACEMENT
        : user_role === "ta"
        ? TA
        : user_role === "career"
        ? CAREER
        : user_role === "mentor"
        ? MENTOR
        :role ==="pd" ? PD: ADVISOR;
    const currentUrl = window.location.pathname;

    // update the active menu state
    const updatedMenuData = user_menu.map((menuGroup) => {
      const updatedItems = menuGroup.Items.map((item) => {
        if (item.path === currentUrl) {
          item.active = true;
        } 
        else {
          item.active = false;
        }

        if (item.children) {
          item.children.forEach((child) => {
            if (child.path !== currentUrl) {
              child.active = false;
            }

            if (child.path === currentUrl) {
              item.active = true;
              child.active = true;
            }
           

            if (child.children) {
              // console.log("Child: ", child, currentUrl)

              child.children.forEach((child2) => {
                if (child2.path !== currentUrl) {
                  child2.active = false;
                }

                if (child2.path === currentUrl) {
                  item.active = true;
                  child.active = true;
                  child2.active = true;
                }

                if (child2.children) {
                  child2.children.forEach((child3) => {
                    if (child3.path !== currentUrl) {
                      child3.active = false;
                    }

                    if (child3.path === currentUrl) {
                      item.active = true;
                      child.active = true;
                      child2.active = true;
                      child3.active = true;
                    }
                  });
                }
              });
            }
          });
        }

        return item;
      });

      return {
        ...menuGroup,
        Items: updatedItems,
      };
    });
    setMainMenu(updatedMenuData);

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [layout]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };
  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };
  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };
  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon";
  };

  const handleMenuClick = (menu, childMenu) => {
    const currentUrl = window.location.pathname;

    const updatedMenuData = MENUITEMS.map((menuGroup) => {
      const updatedItems = menuGroup.Items.map((item) => {
        if (item.path !== currentUrl) {
          if (item === menu) {
            item.active = true;
          } 
          // else {
          //   item.active = false;
          // }

          if (item.children) {
            item.children.forEach((child) => {

              // if (child !== childMenu) {
              //   // child.active = false;
              // }

              if (child.children) {
                child.children.forEach((child2) => {
                  // if (child2 !== childMenu) {
                  //   child2.active = false;
                  // }

                  if (child2.children) {
                  
                    // child2.children.forEach((child3) => {
                    //   if (child3 !== childMenu) {
                    //     child3.active = false;
                    //   }
                    // });

                    if (child2.children.includes(childMenu)) {
                      item.active = true;
                      child.active = true;
                      child2.active = true;
                      childMenu.active = true;
                    }
                  }
                });

                if (child.children.includes(childMenu)) {
                  item.active = true;
                  child.active = true;
                  childMenu.active = true;
                }
              }
            });

            if (item.children.includes(childMenu)) {
              item.active = true;
              childMenu.active = true;
            }
          }
        }
        return item;
      });

      return {
        ...menuGroup,
        Items: updatedItems,
      };
    });
    setMainMenu({ mainmenu: updatedMenuData });
  };

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={window.location.pathname}>
            <div style={{ height: 27 }}>
              <img
                className="img-fluid for-light"
                src={require("../../assets/images/logo/login.png")}
                alt=""
                // style={{ height: 45}}
              />
              <img
                className="img-fluid for-dark"
                src={require("../../assets/images/logo/login.png")}
                alt=""
                // style={{ height: 45 }}
              />
            </div>
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={window.location.pathname}>
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/login.png")}
              alt=""
              width={35}
            />
          </Link>
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper.split(" ").includes("horizontal-wrapper")
                ? { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{"Back"}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              {MENUITEMS.map((Item, i) => (
                <Fragment key={i}>
                  {/* Main Menu  */}
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {/* Child 1 */}

                      {menuItem.type === "sub" ? (
                        <a
                          href="javascript"
                          className={`sidebar-link sidebar-title ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            handleMenuClick(menuItem, menuItem);
                          }}
                        >
                          {menuItem.icon ? <menuItem.icon /> : null}
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}
                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={() => handleMenuClick(Item, menuItem)}
                        >
                          {menuItem.icon ? <menuItem.icon /> : null}
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}
                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {/* Child 2 */}
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index} className="sidebar-main-title">
                                {childrenItem.type === "heading" && (
                                  <Fragment>
                                    <p
                                      style={{
                                        color: "#7366ff",
                                        marginLeft: 5,
                                        marginRight: 5,
                                        marginTop: 10,
                                        fontSize: 14,
                                        fontWeight: 500,
                                        backgroundColor: "#F7F6FF",
                                        borderRadius: 10,
                                        padding: "8px 10px",
                                      }}
                                    >
                                      {childrenItem.icon}{" "}
                                      {childrenItem.menutitle}
                                    </p>

                                    {childrenItem.children.map(
                                      (childrenSubItem, index) => {
                                        return (
                                          <li key={index}>
                                            {childrenSubItem.type ===
                                              "link" && (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                style={{
                                                  padding: "5px 0px",
                                                }}
                                                // style={{ marginLeft: 4 }}
                                                onClick={() =>
                                                  handleMenuClick(
                                                    childrenItem,
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                {childrenSubItem.title}
                                              </Link>
                                            )}

                                            {childrenSubItem.type === "sub" && (
                                              <a
                                                href="javascript"
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  handleMenuClick(
                                                    childrenItem,
                                                    childrenSubItem
                                                  );
                                                }}
                                              >
                                                {childrenSubItem.title}
                                                <span className="sub-arrow">
                                                  <i className="fa fa-chevron-right"></i>
                                                </span>
                                                <div className="according-menu">
                                                  {childrenSubItem.active ? (
                                                    <i className="fa fa-angle-down"></i>
                                                  ) : (
                                                    <i className="fa fa-angle-right"></i>
                                                  )}
                                                </div>
                                              </a>
                                            )}
                                          
                                            {
                                            childrenSubItem.children && (
                                              <ul
                                                className="nav-sub-childmenu submenu-content"
                                                style={
                                                  childrenSubItem.active
                                                    ? { display: "block" }
                                                    : { display: "none" }
                                                }
                                              >
                                                 {childrenSubItem.children.map(
                                                  (childrenSubItem1, key) => (
                                                    <li key={key}>
                                                      {childrenSubItem1.type ===
                                                      "link" ? (
                                                        <Link
                                                          to={
                                                            childrenSubItem1.path
                                                          }
                                                          className={`${
                                                            childrenSubItem1.active
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                          style={{
                                                            marginLeft:-20
                                                            // padding: "7px 0px",
                                                          }}
                                                          onClick={() =>
                                                            handleMenuClick(
                                                              childrenSubItem,
                                                              childrenSubItem1
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          <i
                                                            className="fa-sharp fa-solid fa-minus"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: 8,
                                                            }}
                                                          ></i>{" "}
                                                          {
                                                            childrenSubItem1.title
                                                          }
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                                
                                              </ul>)}
                                            {/* {childrenSubItem?.children?.map(
                                              (childrenSubItem1, index) => {
                                                return (
                                                  <li key={index}>
                                                    {childrenSubItem1.type ===
                                                      "link" && (
                                                      <Link
                                                        to={
                                                          childrenSubItem1.path
                                                        }
                                                        className={`${
                                                          childrenSubItem1.active
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          padding: "5px 0px",
                                                        }}
                                                        // style={{ marginLeft: 4 }}
                                                        onClick={() =>
                                                          handleMenuClick(
                                                            childrenSubItem,
                                                            childrenSubItem1
                                                          )
                                                        }
                                                      >
                                                        {childrenSubItem1.title}
                                                      </Link> 
                                                    )}

                                                    {childrenSubItem1.type ===
                                                      "sub" && (
                                                      <a
                                                        href="javascript"
                                                        className={`${
                                                          childrenSubItem1.active
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        onClick={(event) => {
                                                          event.preventDefault();
                                                          handleMenuClick(
                                                            childrenSubItem,
                                                            childrenSubItem1
                                                          );
                                                        }}
                                                      >
                                                        {childrenSubItem1.title}
                                                        <span className="sub-arrow">
                                                          <i className="fa fa-chevron-right"></i>
                                                        </span>
                                                        <div className="according-menu">
                                                          {childrenSubItem1.active ? (
                                                            <i className="fa fa-angle-down"></i>
                                                          ) : (
                                                            <i className="fa fa-angle-right"></i>
                                                          )}
                                                        </div>
                                                      </a>
                                                    )}
                                                  </li>
                                                );
                                              }
                                            )} */}
                                          </li>
                                        );
                                      }
                                    )}
                                  </Fragment>
                                )}

                                {childrenItem.type === "sub" ? (
                                  <a
                                    href="javascript"
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      handleMenuClick(menuItem, childrenItem);
                                    }}
                                  >
                                    {childrenItem.title}
                                    <span className="sub-arrow">
                                      <i className="fa fa-chevron-right"></i>
                                    </span>
                                    <div className="according-menu">
                                      {childrenItem.active ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-right"></i>
                                      )}
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}
                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={childrenItem.path}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    style={{
                                      padding: "7px 0px",
                                    }}
                                    // style={{ marginLeft: 4 }}
                                    onClick={() =>
                                      handleMenuClick(menuItem, childrenItem)
                                    }
                                  >
                                    {childrenItem.icon}
                                    {childrenItem.title}
                                    {childrenItem.badge ? (
                                      <label
                                        className={childrenItem.badge}
                                        style={{
                                          marginTop: -8,
                                          fontWeight: "bold",
                                          marginRight: -25,
                                        }}
                                      >
                                        {childrenItem.badgetxt}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {/* Child 3 */}
                                {childrenItem?.type !== "heading" &&
                                childrenItem.children ? (
                                  <ul
                                    className="nav-sub-childmenu submenu-content"
                                    style={
                                      childrenItem.active
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => {
                                        // {childrenItem.type === "heading" && console.log("Children: ", childrenSubItem)}

                                        return (
                                          <li key={key}>
                                            {childrenSubItem.type === "sub" ? (
                                              <a
                                                href="javascript"
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                style={{
                                                  padding: "7px 0px",
                                                }}
                                                onClick={(event) => {
                                                  event.preventDefault();

                                                  handleMenuClick(
                                                    childrenItem,
                                                    childrenSubItem
                                                  );
                                                }}
                                              >
                                                <i
                                                  className="fa-sharp fa-solid fa-minus"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: 8,
                                                    marginLeft: -15,
                                                  }}
                                                ></i>{" "}
                                                {childrenSubItem.title}
                                                <span className="sub-arrow">
                                                  <i className="fa fa-chevron-right"></i>
                                                </span>
                                                <div className="according-menu">
                                                  {childrenSubItem.active ? (
                                                    <i className="fa fa-angle-down"></i>
                                                  ) : (
                                                    <i className="fa fa-angle-right"></i>
                                                  )}
                                                </div>
                                              </a>
                                            ) : (
                                              ""
                                            )}

                                            {childrenSubItem.type === "link" ? (
                                              <Link
                                                to={childrenSubItem.path}
                                                className={`${
                                                  childrenSubItem.active
                                                    ? "active"
                                                    : ""
                                                }`}
                                                style={{
                                                  padding: "7px 0px",
                                                }}
                                                onClick={() =>
                                                  handleMenuClick(
                                                    childrenItem,
                                                    childrenSubItem
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa-sharp fa-solid fa-minus"
                                                  aria-hidden="true"
                                                  style={{
                                                    fontSize: 8,
                                                    marginLeft: -15,
                                                  }}
                                                ></i>{" "}
                                                {childrenSubItem.title}
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                            {/* Child 4 */}
                                            {childrenSubItem.children ? (
                                              <ul
                                                className="nav-sub-childmenu submenu-content"
                                                style={
                                                  childrenSubItem.active
                                                    ? { display: "block" }
                                                    : { display: "none" }
                                                }
                                              >
                                                {childrenSubItem.children.map(
                                                  (childrenSubItem1, key) => (
                                                    <li key={key}>
                                                      {childrenSubItem1.type ===
                                                      "link" ? (
                                                        <Link
                                                          to={
                                                            childrenSubItem1.path
                                                          }
                                                          className={`${
                                                            childrenSubItem1.active
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                          style={{
                                                            padding: "7px 0px",
                                                          }}
                                                          onClick={() =>
                                                            handleMenuClick(
                                                              childrenSubItem,
                                                              childrenSubItem1
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          <i
                                                            className="fa-sharp fa-solid fa-minus"
                                                            aria-hidden="true"
                                                            style={{
                                                              fontSize: 8,
                                                            }}
                                                          ></i>{" "}
                                                          {
                                                            childrenSubItem1.title
                                                          }
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  // end
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                      {/* new */}
                      {/* {menuItem.items ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem?.items?.map((childmenuItem, index) => (
                            <Fragment key={index}>
                              <li className="sidebar-main-title">
                                <div style={{}}>
                                  <p
                                    style={{
                                      color: "#7366ff",
                                      marginLeft: 5,
                                      fontSize: 14,
                                      fontWeight: "bold",
                                      // marginleft:10
                                    }}
                                  >
                                    {" "}
                                    {childmenuItem.menutitle === "Student" ? (
                                      <Users
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle ===
                                      "Attendance" ? (
                                      <FileText
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle ===
                                      "Summary" ? (
                                      <Folder
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle === "Admin" ? (
                                      <User
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle === "Notifications" ? (
                                      <Bell
                                        size={16}
                                        style={{ marginRight: 5 }}
                                      />
                                    ) : childmenuItem.menutitle === "Payments" ? (
                                    <DollarSign
                                      size={16}
                                      style={{ marginRight: 5 }}
                                    />) : childmenuItem.menutitle ===
                                    "Mobile App" ? (
                                    <App
                                      size={16}
                                      style={{ marginRight: 5 }}
                                    />): null  }{" "}
                                    {childmenuItem.menutitle}
                                  </p>
                                </div>
                              </li>
                              {childmenuItem.children.map(
                                (childrenItem, index) => {
                                  return (
                                    <li key={index} style={{ marginLeft: 10 }}>
                                      {childrenItem.type === "sub" ? (
                                        <a
                                          href="javascript"
                                          className={`${
                                            childrenItem.active ? "active" : ""
                                          }`}
                                          style={{ marginLeft: -15 }}
                                          onClick={(event) =>
                                          {  event.preventDefault();
                                             handleMenuClick(
                                              childmenuItem,
                                              childrenItem
                                            )}
                                          }
                                         
                                        >
                                          {childrenItem.title}
                                          <span className="sub-arrow">
                                            <i className="fa fa-chevron-right"></i>
                                          </span>
                                          <div className="according-menu">
                                            {childrenItem.active ? (
                                              <i className="fa fa-angle-down"></i>
                                            ) : (
                                              <i className="fa fa-angle-right"></i>
                                            )}
                                          </div>
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {childrenItem.type === "link" ? (
                                        <>
                                          {(role === "mentor" ||
                                            role === "admin" ||
                                            (user?.username === "mehak" &&
                                              role === "advisor")) &&
                                          (childrenItem.title ===
                                            "Mentorship Session" ||
                                            childrenItem.title ===
                                              "Manage Mentors") ? ( 
                                            <Link
                                              to={childrenItem.path}
                                              className={`${
                                                childrenItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              style={{ marginLeft: -15 }}
                                              onClick={() =>
                                                handleMenuClick(
                                                  childmenuItem,
                                                  childrenItem
                                                )
                                              }
                                            >
                                              {childrenItem.title}
                                            </Link>
                                          ) : null}

                                          {
                                          (childrenItem.title !==
                                            "Mentorship Session" &&
                                            childrenItem.title !==
                                              "Manage Mentors") ? (
                                            <Link
                                              to={childrenItem.path}
                                              className={`${
                                                childrenItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              style={{ marginLeft: -15 }}
                                              onClick={() =>{
                                                console.log(childmenuItem,childrenItem)
                                                handleMenuClick(
                                                  childmenuItem,
                                                  childrenItem
                                                )}
                                              }
                                            >
                                              {childrenItem.title}
                                            </Link>
                                          ) : null}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {childrenItem.children ? (
                                        <ul
                                          className="nav-sub-childmenu submenu-content"
                                          style={
                                            childrenItem.active
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                        >
                                          {childrenItem.children.map(
                                            (childrenSubItem, key) => (
                                              <li key={key}>
                                                {childrenSubItem.type ===
                                                "link" ? (
                                                  <Link
                                                    to={childrenSubItem.path}
                                                    className={`${
                                                      childrenSubItem.active
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    style={{
                                                      padding: "7px 0px",
                                                      marginLeft: -30,
                                                    }}
                                                    onClick={() =>
                                                      handleMenuClick(
                                                        childrenItem,
                                                        childrenSubItem
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa-sharp fa-solid fa-minus"
                                                      aria-hidden="true"
                                                      style={{
                                                        fontSize: 8,
                                                      }}
                                                    ></i>{" "}
                                                    {childrenSubItem.title}
                                                  </Link>
                                                ) : (
                                                  ""
                                                )}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                                }
                              )}
                              <div
                                className="sidebar-main-title"
                                style={{
                                  borderBottom: "2px solid #efefef",
                                  marginBottom: 15,
                                  marginTop: 10,
                                }}
                              ></div>
                            </Fragment>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )} */}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};
export default Sidebar;
